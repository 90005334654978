<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <!-- Header -->
    <Header :shareBtn="true" />
    <!--End Header -->

    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <div class="page-title-clear"></div>
    <div class="page-content" v-if="!isLoading">
      <!-- Company -->
      <div class="card card-style">
        <div class="align-self-center mt-5 text-center">
<!--          <img-->
<!--            :src="logoUrl"-->
<!--            class="rounded-m"-->
<!--            width="80"-->
<!--            @error="imageUrlAlt"-->
<!--          />-->
          <div
            class="card bg-transparent"
          >
            <div class="align-self-center mt-5 text-center">
              <img
                @error="imageUrlAlt"
                :src="logoUrl"
                class="card-img rounded-m preload-img img-fluid entered loaded"
                alt=""
                style="height: 84px; width: 84px; object-fit: cover"
              />
              <div
                class="card-img-overlay p-0 d-flex align-items-end mx-auto"
                style="width: 84px;"
                v-if="companyDetail.status_badge === 1"
              >
                <div
                  class="w-100 text-center opacity-90"
                  style="background-color: #fb6e50 !important; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;"
                >
                  <span class="fw-bold text-white" style="font-size: x-small">#HIRING</span>
                </div>
              </div>
            </div>

          </div>
          <p class="mb-0 mt-2 font-800">
            {{ companyDetail.business_name }}
          </p>
        </div>

        <div class="content">
          <p class="mb-2">
            {{ companyDetail.long_about }}
          </p>
          <p class="mb-1">
            {{ companyDetail.contact_name }}
            {{ companyDetail.phone_number }}
          </p>
          <p class="mb-2">
            {{ companyDetail.city.name }}, {{ companyDetail.province.name }},
            {{ companyDetail.country.name }}
          </p>
          <p class="mb-2">
            {{ companyDetail.website }}
          </p>
        </div>
      </div>
      <!-- End Company -->
      <!--Job Listing -->
      <ListJob v-for="job in listJob" :key="job.id" :job="job" />
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import EmptyState from "@/components/EmptyState";

import ListJob from "@/components/list_job/ListJob";
export default {
  name: "CompanyJobList",
  props: {
    job: Object,
  },
  inject: ["getApply"],
  components: {
    Header,
    Footer,
    ListJob,
    EmptyState,
  },
  data() {
    return {
      dataSearch: "",
      resetFilter: false,
      dataFilterList: {},
      logoUrl: axios.defaults.baseURL,
      listJob: [],
      apiUrl: "/api/v1/job-seeker/job-list/vacancy/",
      isAuthenticated: this.$store.state.isAuthenticated,
      isLoading: true,
      next: "",
      reqData: false,
      isFilter: false,
      companyDetail: "",
    };
  },
  beforeMount() {},
  mounted() {
    document.title = "pasjob";
    var companyProfile = this.$route.params.id;
    init_template();
    this.getData();
  },
  methods: {
    menuOpen,
    menuClose,
    imageUrlAlt(event) {
      event.target.src = "/images/defaults/default_logo.png";
    },
    /*company detail*/
    async getCompanyDetail(companyProfile) {
      await axios
        .get(
          "/api/v1/job-seeker/company-detail/?company_profile=" + companyProfile
        )
        .then((response) => {
          console.log(response);
          this.companyDetail = response.data[0];
          this.logoUrl = response.data[0].get_logo;
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data getApply : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error.message));
          }
        });
    },
    getMoreJobs() {
      window.onscroll = () => {
        var bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight - 2;
        if (bottomOfWindow && this.next) {
          console.log("get more job");
          this.getJobList();
        }
        if (bottomOfWindow && !this.isAuthenticated) {
          this.menuOpen("need-login");
        }
      };
    },
    /*listing job*/
    async getJobList(CompanyProfile) {
      var url =
        "/api/v1/job-seeker/job-list/company/?company_profile=" +
        CompanyProfile;
      this.next ? (url = this.next) : "";
      if (!this.reqData) {
        this.reqData = true;
        await axios
          .get(url)
          .then((response) => {
            console.log(response.data);
            this.next = response.data.next;
            response.data.results.forEach((item) => {
              if (!this.listJob.includes(item)) {
                this.listJob.push(item);
              }
            });
            console.log(this.listJob);
            this.isLoading = false;
            this.reqData = false;
          })
          .catch((error) => {
            this.reqData = false;
            console.log(error);
          });
      }
    },

    /*filter job*/
    reloadPage() {
      setTimeout(() => {
        window.location.reload(true);
      }, 500);
    },
    async getData() {
      const companyProfile = this.$route.params.id;
      this.getJobList(companyProfile);
      this.getCompanyDetail(companyProfile);
    },
    async apply(job_id) {
      if (!this.isAuthenticated) {
        console.log("not auth : ", this.isAuthenticated);
        this.menuOpen("required-login");
      } else if (!this.isVerified) {
        console.log("not verify");
        this.menuOpen("emailVerifyNotif");
      }
      const dataApplyJob = this.$store.state.apply;
      if (dataApplyJob) {
        const apply_job_id = dataApplyJob.map((x) => x.job);
        if (!apply_job_id.includes(job_id) && this.isAuthenticated) {
          /*apply job*/
          const apiUrl = "api/v1/job-seeker/job/apply/";

          const formData = {
            job: job_id,
          };

          await axios
            .post(apiUrl, formData)
            .then((response) => {
              console.log("response data ", response.data);
              var apply = this.$store.state.apply;
              apply.push(response.data);
              this.$store.commit("addApply", apply);
              this.disableBtn = true;
              this.btnText = "Already Applied";
              this.menuOpen("applied");
            })
            .catch((error) => {
              if (error.response) {
                for (const property in error.response.data) {
                  this.errors.push(
                    `${property}: ${error.response.data[property]}`
                  );
                }

                console.log(
                  "response data getApply : " + JSON.stringify(error.response)
                );
              } else if (error.message) {
                console.log("error :" + JSON.stringify(error));
              }
            });
        }
      }
    },
  },
};
</script>

<style>
.keep-spaces {
  white-space: pre-wrap;
}
.bluer-layer {
  position: relative;
  filter: blur(4px);
}
#text-information {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(208, 208, 208, 0.34);
  /*! filter: blur(5px); */
  padding: 20px;
  color: black;
  border-radius: 20px;
}
</style>
